<template>
    <div>
        
        <div class="flex-column justify-center align-items-center" style="height:calc(100vh - 55px);width:100vw">
            <div class="flex-column justify-center align-items-center margin-bottom-30">
                <div class="margin-bottom-30">
                    <i class="fa fa-desktop icon color-sub"></i>
                    <i class="fa fa-ban text-danger fa-2x color-red" style="margin-left:-30px"></i>
                </div>
            
                <div class="title">暂无权限</div>
                <!-- <div class="bold font-size-24 margin-top-10">请联系客服经理开通权限！</div> -->
                <div class="margin-top-10 margin-left-20 margin-right-20">您还没有开通模块功能权限</div>
                <div class="margin-top-20 margin-bottom-30">
                    <!-- <el-button type="primary" size="small" @click="goto">返回</el-button> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {  },
    data() {
        return {}
    },
    created() {
        
    },
    methods:{
        goto(){
            this.$router.replace({
                path: this.$route.query.redirect||'/',
            })
        }
    }
}
</script>
<style scoped>
    .icon{
        font-size: 100px;
    }
    .title{
        font-size: 44px;
        font-weight: bold;
    }
</style>